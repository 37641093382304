import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InputText from "root/components/InputText";
import Typography from "root/components/Typography";
import AssociationsMap from "root/components/AssociationsMap";
import AskSioslifeForm from "root/components/AskSioslifeForm";
import institutionsData from "root/data/institutions.json";

import styles from "./index.module.css";

const normalizedInstitutionsName = institutionsData.institutions.map(
  institution => {
    return {
      name: institution.name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, ""),
    };
  },
);

const SearchInstitution = ({
  findInstitution,
  askSioslifeForm,
  color,
  page,
}) => {
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState("");

  const searchOptions = async newSearch => {
    const currentOptions = [];
    const searchWords = newSearch
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .split(/\s+/)
      .filter(token => token.length > 0);

    normalizedInstitutionsName.forEach((institution, index) => {
      const wordsPositions = searchWords.map(word =>
        institution.name.search(word),
      );

      const minPosition =
        wordsPositions.length < 1 ? -1 : Math.min(...wordsPositions);

      if (minPosition > -1) {
        currentOptions.push(institutionsData.institutions[index]);
      }
    });

    setOptions(currentOptions);
  };

  const handleInputChange = event => {
    event.preventDefault();

    setSelectedInstitution("");
    setSearch(event.target.value);
    searchOptions(event.target.value);
  };

  const handleOnOptionClick = event => {
    event.preventDefault();

    setSelectedInstitution(event.currentTarget.dataset.id);
    setOptions([]);
    setSearch(event.currentTarget.dataset.name);
  };

  const optionClassName = classNames(styles[color], styles.option);

  const renderOptions = () => {
    return (
      <div className={styles.options}>
        {options.slice(0, 7).map(option => (
          <div
            type="button"
            role="option"
            aria-selected="false"
            key={option.name}
            className={optionClassName}
            data-name={option.name}
            data-id={option.id}
            onClick={handleOnOptionClick}
            onKeyDown={handleOnOptionClick}
            tabIndex="-1"
          >
            <Typography color="black">{option.name}</Typography>
          </div>
        ))}
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div>
        {selectedInstitution === "" ? null : (
          <div className={styles.isSioslife}>
            <Typography
              weight="medium"
              color={color === "blue" ? "blue" : "black"}
            >
              {findInstitution.institutionSiolife}
            </Typography>
          </div>
        )}
        {search !== "" && options.length === 0 && selectedInstitution === "" ? (
          <div className={styles.isSioslife}>
            <Typography color="black">
              {findInstitution.institutionNotSiolife}
            </Typography>
            <Typography color="black">
              {findInstitution.institutionNotSiolifeQuestion}
            </Typography>
            <div className={styles.askButton}>
              <AskSioslifeForm translations={askSioslifeForm} color={color} />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const mapInstitutionIds = options
    .slice(0, 7)
    .map(institution => institution.id.toString());

  mapInstitutionIds.push(selectedInstitution);

  const searchClassName = classNames(styles[page], styles.search);

  return (
    <div className={styles.root}>
      <div className={styles.map}>
        <AssociationsMap color={color} selected={mapInstitutionIds} />
      </div>
      <div className={searchClassName}>
        <div className={styles.findInstitutionText}>
          <Typography variant="h2" weight="medium" color={color}>
            {findInstitution.findInstitutionTitle}
          </Typography>
        </div>
        <InputText
          type="search"
          placeholder={findInstitution.searchPlaceholder}
          handleOnChange={handleInputChange}
          value={search}
          borderColor={color === "blue" ? "yellow" : color}
        />
        {renderOptions()}
        <div className={styles.formDesktop}>{renderForm()}</div>
      </div>
      <div className={styles.formMobile}>{renderForm()}</div>
    </div>
  );
};

SearchInstitution.propTypes = {
  color: PropTypes.oneOf(["purple", "red", "green", "blue"]).isRequired,
  findInstitution: PropTypes.shape({
    findInstitutionTitle: PropTypes.string.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
    institutionSiolife: PropTypes.string.isRequired,
    institutionNotSiolife: PropTypes.string.isRequired,
    institutionNotSiolifeQuestion: PropTypes.string.isRequired,
  }).isRequired,
  askSioslifeForm: PropTypes.shape({
    namePlaceholder: PropTypes.string.isRequired,
    emailPlaceholder: PropTypes.string.isRequired,
    phonePlaceholder: PropTypes.string.isRequired,
    institutionNamePlaceholder: PropTypes.string.isRequired,
    institutionPositionPlaceholder: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
  }).isRequired,
  page: PropTypes.oneOf(["home", "product"]),
};

SearchInstitution.defaultProps = {
  page: "home",
};

export default SearchInstitution;
