import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import classNames from "classnames";
import Typography from "root/components/Typography";
import Button from "root/components/Button";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import Apostrophe from "root/components/SvgColor/Apostrophe";

import styles from "./index.module.css";

const mobileW = 800;

const Testimonials = ({
  translations: { quote, years, buttonLabel },
  color,
  videoUrl,
  image,
}) => {
  const dimensions = useWindowDimensions();

  return (
    <div className={classNames(styles.root, styles[color])}>
      <div className={styles.content}>
        <div className={styles.imagesContent}>
          <div className={styles.testimonialBackGrid}>
            <Grid
              columns={dimensions.width > mobileW ? 27 : 18}
              lines={dimensions.width > mobileW ? 18 : 27}
              color="white"
              opacity={0.3}
            />
          </div>
          <div className={styles.testimonialApostrophe}>
            <Apostrophe color={color === "blue" ? "yellow" : "white"} />
          </div>
          <Img
            className={styles.testimonialImage}
            alt="testimonial"
            fluid={image.testimonial.childImageSharp.fluid}
            objectFit="cover"
          />
        </div>
        <div className={styles.mainContent}>
          <div className={styles.quote}>
            <Typography variant="h3" weight="medium">
              {quote}
            </Typography>
          </div>
          <div className={styles.personDetailsQuote}>
            <Typography>{`Maria Conceição, 85 ${years}`}</Typography>
          </div>
          <a
            href="https://www.instagram.com/sioslife/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className={styles.buttonSmiles}>
              <Button
                color={color === "blue" ? "yellow" : "white"}
                size="large"
              >
                <Typography
                  color={color === "blue" ? "white" : color}
                  weight="medium"
                >
                  {buttonLabel}
                </Typography>
              </Button>
            </div>
          </a>
          <div className={styles.youtubeVideo}>
            <iframe
              style={{
                width: "100%",
                height: "100%",
              }}
              title="sioslife video"
              src={videoUrl}
              frameBorder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Testimonials.propTypes = {
  translations: PropTypes.shape({
    quote: PropTypes.string.isRequired,
    years: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
  }).isRequired,
  color: PropTypes.oneOf(["blue", "red", "purple", "green"]).isRequired,
  videoUrl: PropTypes.string.isRequired,
  image: PropTypes.shape().isRequired,
};

export default Testimonials;
